import { Popup } from "react-leaflet";
import { Button, Pane, Text } from "evergreen-ui";
import { useRef } from "react";
import { Popup as LPopup } from "leaflet";

import { IPlace } from "../../../../../api/Types";
import { UseBooleanOutput } from "../../../../../models/UseBooleanOutput";

export interface PlaceMarkerPopupProps {
    place?: IPlace | null,
    newPlaceDiadlog?: UseBooleanOutput | null;
};

const PlaceMarkerPopup = ({ place, newPlaceDiadlog }: PlaceMarkerPopupProps) => {
    const popupRef = useRef<LPopup>(null);

    return (
        <Popup ref={popupRef}>
            {
                place ? (
                    <Pane>
                        <Text>
                            {place.name}
                        </Text>
                    </Pane>
                ) : (
                    <Pane>
                        {
                            newPlaceDiadlog ? (
                                <Button
                                    disabled={!!place}
                                    onClick={newPlaceDiadlog.setTrue}
                                >
                                    Добавить новое место
                                </Button>
                            ) : null
                        }
                    </Pane>
                )
            }
        </Popup>
    );
};

PlaceMarkerPopup.defaultProps = {
    place: null,
    setCoords: null,
    newPlaceDiadlog: null
}

export default PlaceMarkerPopup;
