import axios from "axios";
import { toaster } from "evergreen-ui";
import { useState } from "react";
import type QrScanner from "qr-scanner";
import { useNavigate } from "react-router";

import type { IEventType, IUploadEventsData, IUploadPipeEventDocumentData } from "../../../../api/Types";
import calcDistance from "../../../helpers/calcDistance.helper";
import geolocatedCoordsToArray from "../../../helpers/geolocatedCoordsToArray.helper";
import isPipeToken from "../../../helpers/isPipeToken.helper";
import useAuthContext from "../../AuthProvider/AuthContext.hook";
import useGeoContext from "../../GeoProvider/GeoContext.hook";
import isPipeIdWithHash from "../../../helpers/isPipeIdWithHash.helper";

export interface ISendData {
    file: File | null;
    result: QrScanner.ScanResult['data'] | null;
    eventType: IEventType | null;
    decoded: false | {
        id: number;
    };
    clearResults: () => void;
    attachments: IUploadPipeEventDocumentData[];
}

const useSendData = ({ file, attachments, result, eventType, decoded, clearResults }: ISendData) => {
    const navigate = useNavigate();
    const { api } = useAuthContext();
    const { confirmedCoords: coords, geolocated, confirmedPlace } = useGeoContext();
    
    const sendData = async (pipeId: number, data: IUploadEventsData) => {
        try {
            const response = await api.uploadPipe(pipeId, data);
            
            if (response.data.id) {
                if (attachments.length) {
                    await Promise.all(attachments.map(
                        (attach) => api.uploadPipeEventDocument(pipeId, data.pipe_token, response.data.id, attach)
                    ))
                }
                clearResults()
                navigate(`/pipes/${pipeId}`)
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toaster.danger('Неправильный QR-код');
            }
        }
    }

    const [loading, setLoading] = useState<boolean>(false);

    const dataChecked = !!(file && result && coords && confirmedPlace && eventType);

    const sendDataHandler = async () => {
        if (dataChecked && geolocated.coords && (isPipeToken(result) || isPipeIdWithHash(result))) {
            setLoading(true)

            const coordsGeo = geolocatedCoordsToArray(geolocated.coords);

            const coordinates = calcDistance(coordsGeo, coords) <= 0.3 ? coordsGeo : coords

            const match = result.match(/(\d+);(.*)/);

            const id = match ? +match[1] : decoded ? decoded.id : 0
            const token = match ? match[2] : result
            
            if (id && token) {
                await sendData(
                    id,
                    { 
                        pipe_photo: file,
                        pipe_token: token,
                        coordinates,
                        place_id: confirmedPlace.id,
                        event_type: eventType
                    }
                )
            }

            setLoading(false)
        }
    }
    return { sendDataHandler, loading, dataChecked }
};

export default useSendData;
