import { Navigate, useLocation } from "react-router";
import useAuthContext from "../AuthProvider/AuthContext.hook";

export interface ProtectedRouteProps extends React.PropsWithChildren {
  redirectPath?: string
};

const ProtectedRoute = ({ redirectPath, children }: Required<ProtectedRouteProps>) => {
    const { accessToken } = useAuthContext();

    const location = useLocation();
    
    if (!accessToken) {
      return <Navigate to={redirectPath} state={{ from: location }} replace />;
    }
  
    return <>{children}</>;
};

ProtectedRoute.defaultProps = {
  redirectPath: '/login'
}

export default ProtectedRoute;
