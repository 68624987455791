import { useContext } from "react";
import GeoContext from "./GeoContext";

export interface IGeoContext {}

const useGeoContext = () => {
    /** GeoContext */
    const ctx = useContext(GeoContext);
    return ctx
};

export default useGeoContext;
