import {  useEffect, useRef, useState } from 'react';
import { Pane, Button, toaster } from 'evergreen-ui';
import QrScanner from 'qr-scanner';
import { useBoolean } from 'usehooks-ts';
import { Html5Qrcode } from 'html5-qrcode';

import type { IPipeIdWithHash, IPipeToken } from '../../../api/Types';
import isPipeToken from '../../helpers/isPipeToken.helper';
import ScanResult from '../ScanResult/ScanResult';
import QRAdapter from '../../helpers/QRAdapter';

import styles from './CameraQRScanner.module.scss';
import isPipeIdWithHash from '../../helpers/isPipeIdWithHash.helper';

const CameraQRScanner = () => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const [result, setResult] = useState<IPipeToken | IPipeIdWithHash | null>(null);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [qrScanner, setQrScanner] = useState<QrScanner | null>(null);
    const [isFromPhoto, setIsFromPhoto] = useState<boolean>(false);

    const stopHandlerRef = useRef<() => void>();
    const takePhotoRef = useRef<() => void>();

    const { value: isPhotoRetake, setTrue: setTrueIsPhotoRetake, setFalse: setFalseIsPhotoRetake } = useBoolean();

    useEffect(() => {
        if (qrScanner) {
            stopHandlerRef.current = () => qrScanner.stop();
        }
    }, [qrScanner])

    const resultHandler = (scanResult: QrScanner.ScanResult) => {
        try {
            if (takePhotoRef.current) {
                takePhotoRef.current();
                takePhotoRef.current = undefined;
                setFalseIsPhotoRetake();
            }
            const data = QRAdapter(scanResult.data)
            if (isPipeToken(data) || isPipeIdWithHash(data)) {
                setResult(data || null);
                setIsFromPhoto(false);
                setTimeout(() => {
                    stopHandlerRef.current?.();
                }, 10_000);
                return
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        if (videoRef.current) {
            setQrScanner(new QrScanner(
                videoRef.current,
                resultHandler,
                {
                    maxScansPerSecond: 10,
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                    returnDetailedScanResult: true
                })
            )
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoRef.current])

    const start = () => {
        try {            
            setUploadedFile(null);
            setResult(null);
            qrScanner?.start();
        } catch (error) {
            //
        }
    }

    const scanFromImage = async (image: File) => {
        try {
            const reader = new Html5Qrcode('reader');
            const { result } = await reader.scanFileV2(image, false);
            
            const data = QRAdapter(result.text)

            if (isPipeToken(data) || isPipeIdWithHash(data)) {
                setIsFromPhoto(true);
                setResult(data);
            } else {
                toaster.warning('Неправильный формат QR-кода');
            }

        } catch (error) {
            console.log(error);
            
            toaster.warning('QR-код не обнаружен');
            clearResults();
        }
    }

    const onChangeFileInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        qrScanner?.stop();
        setResult(null);
        setUploadedFile(null);
        const { files } = ev.target;
        if (files) {
            setUploadedFile(files?.[0] || null);
            scanFromImage(files?.[0]);
        }
    }

    const inputFileRef = useRef<HTMLInputElement>(null);

    const uploadButtonClick = () => {
        inputFileRef.current?.click();
    }

    const getImage = (video?: HTMLVideoElement | null) => {
        if (video) {
            const canvas = document.createElement("canvas");
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext("2d");
            ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

            const callback = async (blob: Blob | null) => {
                if (blob) {
                    const file = new File(
                        [blob], 
                        `image_${Date.now()}.jpg`,
                        { type: 'image/jpg' }
                    );
                    setUploadedFile(file);
                }
            }
    
            canvas.toBlob(callback)
        }
    }

    const getImageHandler = () => {
        takePhotoRef.current = () => getImage(videoRef.current);
        qrScanner?.start();
        setTrueIsPhotoRetake();

        setTimeout(() => {
            if (takePhotoRef.current) {
                takePhotoRef.current = undefined;
                setFalseIsPhotoRetake();
                toaster.warning('Не удалось переснять фото, QR-код не обнаружен');
                stopHandlerRef.current?.();
            }
        }, 5_000);
    }

    const clearResults = () => {
        setResult(null);
        setUploadedFile(null);
    }

    const cancelHandler = () => {
        qrScanner?.stop();
        clearResults();
    }

    return (
        <Pane maxWidth={390} className={styles.scanner}>
            <video ref={videoRef} className={styles.video} />
            {/* <Html5QrcodePlugin
                fps={10}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
            /> */}
            <div id="reader"></div>
            <ScanResult 
                result={result} 
                cancel={cancelHandler} 
                file={uploadedFile} 
                getImage={getImageHandler}
                isPhotoRetake={isPhotoRetake}
                isFromPhoto={isFromPhoto}
            />
            <div className={styles.buttons}>
                <Button appearance="primary" size="large" onClick={start}>Сканировать QR-код</Button>
                <Button size="large" onClick={uploadButtonClick}>Сканировать из фото</Button>
                <input 
                    type="file" 
                    onClick={cancelHandler}
                    onChange={onChangeFileInput}
                    value=''
                    ref={inputFileRef} 
                    hidden
                    accept="image/png, image/jpeg" 
                />
            </div>
        </Pane>
    );
};

export default CameraQRScanner;
