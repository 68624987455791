import { ICoords } from "../../models/ICoords";
import degreesToRadians from "./degreesToRadians.helper";

const RADIUS = 6371;

const calcDistance = (startCoords: ICoords, destCoords: ICoords) => {
    const [startingLat, startingLong, destinationLat, destinationLong] 
        = [...startCoords, ...destCoords].map((degrees) => degreesToRadians(degrees));

    const distanceInKilometers = 
        Math.acos(Math.sin(startingLat) * Math.sin(destinationLat) +
        Math.cos(startingLat) * Math.cos(destinationLat) * Math.cos(startingLong - destinationLong)) * RADIUS;

    return distanceInKilometers;
}

export default calcDistance;
