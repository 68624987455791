import { useEffect } from "react";
import { useBoolean } from "usehooks-ts";

import calcDistance from "../../helpers/calcDistance.helper";
import geolocatedCoordsToArray from "../../helpers/geolocatedCoordsToArray.helper";
import useGeoContext from "./GeoContext.hook";

const GeoEffects = () => {
    const isCoordsConfirmed = useBoolean(false);

    const {  
        confirmedCoords, 
        geolocated, 
        setConfirmedPlace, 
        setConfirmedCoords 
    } = useGeoContext();

    useEffect(() => {
        if (confirmedCoords) {
            if (geolocated.coords) {
                const distance = calcDistance(confirmedCoords, geolocatedCoordsToArray(geolocated.coords));
                if (distance > 0.3 && !isCoordsConfirmed.value) {
                    setConfirmedPlace(null);
                    setConfirmedCoords(null);
                }
                isCoordsConfirmed.setTrue()
            }
        } else {
            isCoordsConfirmed.setTrue()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geolocated.coords, confirmedCoords])

    return null;
};

export default GeoEffects;
