import { isString } from "lodash";
import type { IPipeIdWithHash } from "../../api/Types";

const isPipeIdWithHash = (data: any): data is IPipeIdWithHash => {
    if (!isString(data)) {
        return false
    }
    const match = data.match(/(\d+);(.*)/);
    return !!match;
}

export default isPipeIdWithHash;
