import { useMap, useMapEvent } from "react-leaflet"
import { ICoords } from "../../../../../models/ICoords"

export interface SetPointOnDragProps {
    setPosition: React.Dispatch<ICoords | null>;
};

const SetPointOnDrag = ({ setPosition }: SetPointOnDragProps) => {
    const map = useMap();
    
    useMapEvent('dragend', () => {
        const center = map.getCenter();
        
        setPosition([center.lat, center.lng])
    })

    return null
}

export default SetPointOnDrag;
