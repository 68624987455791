
import { ceil, debounce, max } from "lodash";
import { useMap, useMapEvent } from "react-leaflet";
import { memo, useMemo } from "react";

import calcDistance from "../../../../helpers/calcDistance.helper";
import { IGetPlacesParams } from "../../../../../api/Types";


export interface CalcMapBoundsProps {
    setBoundsDistance: React.Dispatch<number>;
    getPlaces: (params: IGetPlacesParams) => any
};

const CalcMapBounds = ({ setBoundsDistance, getPlaces }: CalcMapBoundsProps) => {
    const mMap = useMap();

    const onZoom = useMemo(() => debounce(() => {
        const bounds = mMap.getBounds();

        const center = bounds.getCenter();
        const westLng = bounds.getWest();
        const northLat = bounds.getNorth();
        
        const d1 = calcDistance([center.lat, center.lng], [center.lat, westLng]);
        const d2 = calcDistance([center.lat, center.lng], [northLat, center.lng]);
        
        const distance = ceil((max([d1, d2]) || 1000) * 1.3) * 1000;
    
        setBoundsDistance(distance);
        getPlaces({
            origin_lat: center.lat,
            origin_long: center.lng,
            m_distance: distance,
            offset: 0,
            limit: 10
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 500, { trailing: true, leading: false}), [])

    useMapEvent('zoomend', onZoom)
    useMapEvent('dragend', onZoom)
    
    return null;
};

export default memo(CalcMapBounds);
