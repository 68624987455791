import { Button, Pane, TextInputField, toaster } from "evergreen-ui";
import { Form, Formik, FormikHelpers } from "formik";
import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ILoginData } from "../../api/Types";
import useAuthContext from "../../app/components/AuthProvider/AuthContext.hook";

import styles from './Login.module.scss';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { api, setAccessToken, accessToken } = useAuthContext();
    const onSubmit = async (values: IValues, formikHelpers: FormikHelpers<IValues>) => {
        try {
            const response = await api.login(values);
            if (response.status === 200 && response.data.access_token) {
                setAccessToken(response.data.access_token)
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toaster.warning(error.response?.data.message)
            }
        }
    }

    useEffect(() => {
        if (accessToken) {
            navigate(location.state?.from || '/');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken])

    return (
        <Pane maxWidth={390} className={styles.loginPane}>
            <Formik 
                initialValues={initialValues} 
                onSubmit={onSubmit}
            >
                {({ handleChange, values }) => (
                    <Form>
                        <h1>Авторизация</h1>
                        <TextInputField 
                            name="email" 
                            label="email" 
                            value={values.email} 
                            onChange={handleChange} 
                        />
                        <TextInputField 
                            name="password" 
                            type="password" 
                            label="password" 
                            value={values.password} 
                            onChange={handleChange} 
                        />
                        <Button
                            appearance="primary"
                            type="submit"
                        >
                            Войти
                        </Button>
                    </Form>
                )}
            </Formik>
        </Pane>
    );
};

interface IValues extends ILoginData {}

const initialValues: IValues = {
    email: '', 
    password: ''
}

export default Login;
