
import { useEffect, useRef, useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { Button, Pane, Spinner } from 'evergreen-ui';
import { CircleMarker, MapContainer, TileLayer } from 'react-leaflet';
import { Map } from 'leaflet';
import { first } from 'lodash';

import myLocationIconSrc from './icons8-my-location-32.png';

import type { ICoords } from '../../../../models/ICoords';
import type { IGetPlacesParams, IPlace } from '../../../../api/Types';
import useGeoContext from '../GeoContext.hook';
import geolocatedCoordsToArray from '../../../helpers/geolocatedCoordsToArray.helper';
import pointCoordsToArray from '../../../helpers/pointCoordsToArray.helper';
import SetPointOnDrag from './components/SetPointOnDrag';
import useAuthContext from '../../AuthProvider/AuthContext.hook';
import NewPlaceDialog from './components/NewPlaceDialog';
import PlaceMarkerPopup from './components/PlaceMarkerPopup';
import CalcMapBounds from './components/CalcMapBounds';
import ClosePopup from './components/ClosePopup';

import styles from './GeoPopup.module.scss';

const GeoPopup = () => {
    const authCtx = useAuthContext()
    const geoCtx = useGeoContext();

    const [boundsDistance, setBoundsDistance] = useState<number>(1000);

    const [coords, setCoords] = useState<ICoords | null>(geoCtx.geolocated.coords 
        ? geolocatedCoordsToArray(geoCtx.geolocated.coords) 
        : null
    );
    const [place, setPlace] = useState<IPlace | null>(geoCtx.confirmedPlace);

    const [places, setPlaces] = useState<IPlace[]>([]);

    const getPlaces = async (params: IGetPlacesParams) => {
        const response = await authCtx.api.getPlaces(params);
        if (response.data) {
            setPlaces(response.data.results)
        }
        return response;
    }

    const mapRef = useRef<Map>(null);

    const checkPlace = async ([lat, long]: ICoords ) => {
        const response = await getPlaces({
            origin_lat: lat,
            origin_long: long,
            m_distance: boundsDistance,
            offset: 0,
            limit: 10
        });
        if (response.data) {
            const p = first(response.data.results);
            if (p && p.m_distance < 300) {
                setPlace(p);
                if (mapRef.current) {
                    mapRef.current.flyTo([p.point.lat, p.point.long])
                }
            } else {
                setPlace(null)
            }
        } else {
            setPlace(null)
        }
    }

    const acceptGeo = () => {
        if (coords && place) {
            geoCtx.setConfirmedCoords([place.point.lat, place.point.long]);
            geoCtx.setConfirmedPlace(place);
            geoCtx.geoPopupHandlers.setFalse();
        }
    }


    useEffect(() => {
        if (coords) {
            checkPlace(coords);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [coords])

    useEffect(() => {
        if (geoCtx.geolocated.coords && ! coords) {
            setCoords(geolocatedCoordsToArray(geoCtx.geolocated.coords));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [geoCtx.geolocated.coords])

    return (
        <BottomSheet
            open={geoCtx.geoPopupHandlers.value && !!authCtx.accessToken}
            className={styles.sheet}
            footer={
                <Pane className={styles.footer}>
                    {
                        geoCtx.geolocated.coords ? (
                            <>
                                <NewPlaceDialog 
                                    coords={coords || geolocatedCoordsToArray(geoCtx.geolocated.coords)}
                                    place={place}
                                    callback={(value) => {
                                        setCoords([value.point.lat, value.point.long]);
                                        setPlace(value);
                                    }}
                                />
                                <Pane className={styles.placesWrap}>
                                    <Pane className={styles.places}>
                                        {
                                            places.map((p) => (
                                                <Button
                                                    key={p.id}
                                                    appearance={place?.id === p.id ? 'primary' : 'default'}
                                                    onClick={() => setCoords([p.point.lat, p.point.long])}
                                                >
                                                    {p.name}
                                                </Button>
                                            ))
                                        }
                                    </Pane>
                                </Pane>
                            </>
                        ) : null
                    }
                    <Pane className={styles.actions}>
                        <Button 
                            appearance="primary" 
                            onClick={acceptGeo}
                            disabled={!coords || !place}
                        >
                            Подтвердить
                        </Button>
                    </Pane>
                </Pane>
            }
        >
            {geoCtx.geolocated.coords ? (
                <Pane className={styles.geoPopupPane}>
                    <h2>Место: {place?.name || 'Не определено'}</h2>
                    <Pane
                        position='relative'
                    >
                        <img src={myLocationIconSrc} alt='' className={styles.mapCenter}/>
                        <MapContainer
                            center={coords || geolocatedCoordsToArray(geoCtx.geolocated.coords)} 
                            zoom={14}
                            style={{ height: 'min(var(--max-app-with) * 0.8, 80vw)', zIndex: 0 }}
                            ref={mapRef}
                            scrollWheelZoom="center"
                            touchZoom="center"
                            markerZoomAnimation
                        >
                            <CalcMapBounds setBoundsDistance={setBoundsDistance} getPlaces={getPlaces}/>
                            <TileLayer
                                attribution='&copy; <a href="https://carto.com/">carto.com</a>'
                                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                            />
                            {/* <CircleMarker
                                center={geolocatedCoordsToArray(geoCtx.geolocated.coords)}
                                fill
                                fillColor='red'
                                fillOpacity={1}
                                color='white'
                                radius={8}
                            /> */}
                            {
                                places.map((p) => (
                                    <CircleMarker 
                                        key={p.id}
                                        center={pointCoordsToArray(p.point)}
                                        fill
                                        fillColor='white'
                                        radius={5}
                                        fillOpacity={1}
                                        color='black'
                                        eventHandlers={{
                                            click: () => {
                                                setCoords([p.point.lat, p.point.long]);
                                            }
                                        }}
                                    >
                                        <PlaceMarkerPopup place={p}/>
                                    </CircleMarker>
                                ))
                            }
                            <ClosePopup />
                            <SetPointOnDrag setPosition={setCoords} />
                        </MapContainer>
                    </Pane>
                </Pane>
                ) : (
                <Pane display="flex" alignItems="center" justifyContent="center" minHeight="5em">
                    <Spinner />
                </Pane>
            )}
        </BottomSheet>
    );
};



export default GeoPopup;
