import { isString } from 'lodash';
import jwtDecode from 'jwt-decode';

import { IPipeToken } from '../../api/Types';

const isPipeToken = (data: any): data is IPipeToken => {
    if (!isString(data)) {
        return false
    }
    try {
        return !!jwtDecode<{ id: number }>(data).id;
    } catch (error) {
        return false
    }
}

export default isPipeToken;