import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import type { 
    IUploadEventsData, 
    ILoginData, 
    ILoginResponse, 
    IUploadEventsResult, 
    IGetPipeResponse, 
    IAddPlaceData,
    IAddPlaceDataResult,
    IGetPlacesResult,
    IGetPlacesParams,
    IGetPipeEventsResponse,
    IPipeToken,
    IGetPipeAvailableEventsResponse,
    IUploadPipeEventDocumentData
} from "./Types";

export default class Api {
    #instance: AxiosInstance;
    constructor({ axiosParams, onUnauthorized }: { axiosParams?: AxiosRequestConfig; onUnauthorized?: () => void; } = {}){
        this.#instance = axios.create(axiosParams);
        this.#instance.interceptors.response.use(
            (response) => response, 
            (error) => {
                if (axios.isAxiosError(error) && onUnauthorized && error.response) {
                    if ([401].includes(error.response.status)) onUnauthorized()
                }
                throw error;
            }
        );
    }

    login = (data: ILoginData) => this.#instance.post<ILoginResponse>('/login', data)

    // TODO: try this.#instance.postForm
    uploadPipe = (pipeId: number, data: IUploadEventsData, conf: AxiosRequestConfig = {}) => 
        this.#instance.post<IUploadEventsResult>(
        `/pipes/${pipeId}/events`,
        data,
        {
            ...conf,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Pipe-Token': `Bearer ${data.pipe_token}`,
                ...conf?.headers
            }
        }
    )

    uploadPipeEventDocument = (
        pipeId: number,
        pipeToken: IPipeToken | string,
        eventId: number, 
        data: IUploadPipeEventDocumentData, 
        conf: AxiosRequestConfig = {}
    ) => this.#instance.post<IUploadEventsResult>(
            `/pipes/${pipeId}/events/${eventId}/documents`,
            data,
            {
                ...conf,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Pipe-Token': `Bearer ${pipeToken}`,
                    ...conf?.headers
                }
            }
        )

    getPipe = (pipeId: number | `${number}`) => this.#instance.get<IGetPipeResponse>(`/pipes/${pipeId}/`)

    getPipeAvailableEvents = (pipeId: number | `${number}`, pipeToken: IPipeToken | string, conf: AxiosRequestConfig = {}) => 
        this.#instance.get<IGetPipeAvailableEventsResponse>(
            `/pipes/${pipeId}/available-events`,
            {
                ...conf,
                headers: {
                    'Pipe-Token': `Bearer ${pipeToken}`,
                    ...conf?.headers
                }
            }
        )

    // TODO: mb Add cache https://www.npmjs.com/package/axios-cache-interceptor
    /** @deprecated use events from getPipe method */
    getPipeEvents = (id: number | `${number}`, params: { page?: number }) => this.#instance.get<IGetPipeEventsResponse>(`/pipes/${id}/events`, { params })

    getPlaces = (params: IGetPlacesParams) => this.#instance.get<IGetPlacesResult>('/places', { params })

    addPlace = (data: IAddPlaceData) => this.#instance.post<IAddPlaceDataResult>('/places', data)
}
