import React from 'react';
import ReactDOM from 'react-dom/client';

import 'react-spring-bottom-sheet/dist/style.css'
import 'react-photo-view/dist/react-photo-view.css';
import 'leaflet/dist/leaflet.css'

import './index.css';
import App from './app/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);