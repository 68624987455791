import { ReactElement, useState } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { format } from "date-fns";

import { IEvent, IEventType } from "../../../../api/Types";

import config from "../../../../app/config";
import { Card, Button, Pane, Tooltip, Avatar, DocumentIcon, ArrowDownIcon, ArrowUpIcon, CogIcon, DisableIcon, LayersIcon, NewLayersIcon, RouteIcon, UpdatedIcon, WrenchIcon, Heading, Paragraph, SideSheet, Tab, Tablist, Text, Image, Link, Badge, CrossIcon } from "evergreen-ui";
import { EVENTS_MAP } from "../../../../app/helpers/events.helper";

import styles from '../../Pipe.module.scss';
import { DOCUMENT_TYPES } from "../../../../api/references";

export interface EventCardProps {
    event: IEvent
};

const EventCard = ({ event }: EventCardProps) => {
    const [isShown, setIsShown] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)

    return (
        <Card className={styles.event}>
            <p className={styles.event__date}>
                Дата:{' '}
                <span className={styles.event__value}>
                    {format(new Date(event.datetime), 'dd.MM.yyyy HH:mm')}
                </span>
            </p>
            <p className={styles.event__placeName}>
                Место: {' '} 
                <span className={styles.event__value}>    
                    {event.place.name} 
                </span>
            </p>
            <Button 
                appearance="secondary" 
                className={styles.event__image}
                onClick={() => setIsShown(true)}
            >
                Подробнее
            </Button>
            <Pane className={styles.event__icons}>
                <div className={styles.event__actor}>
                    <Tooltip
                        content={`${event.actor.first_name} ${event.actor.middle_name} ${event.actor.last_name} (${event.actor.phone})`}
                    >
                        <Avatar 
                            name={`${event.actor.first_name} ${event.actor.last_name}`} 
                            size={40}
                        />
                    </Tooltip>
                </div>
                {
                    EVENTS_MAP[event.type] ? (
                        <p className={styles.event__type}>
                            <Tooltip content={`Тип события: ${EVENTS_MAP[event.type].label}`}>
                                <span className={styles.event__value}>
                                    {EVENTS_ICONS[event.type]}
                                </span>
                            </Tooltip>
                        </p>
                    ) :  null
                }
                {
                    event.documents.length ? (
                        <p className={styles.event__documents}>
                            <Tooltip content={`Загружено документов: ${event.documents.length}`}>
                                <span className={styles.event__value}>
                                    <DocumentIcon />
                                </span>
                            </Tooltip>
                        </p>
                    ) : null
                }
            </Pane>
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width="min(var(--max-app-with), 100vw)"
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
            >
                <Button
                    appearance="secondary"
                    borderRadius={100}
                    padding={5}
                    onClick={() => setIsShown(false)}
                    position="fixed"
                    top={10}
                    right={10}
                    zIndex={10}
                >
                    <CrossIcon />
                </Button>
                <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                <Pane padding={16} borderBottom="muted">
                    <Heading size={600}>{EVENTS_MAP[event.type].label}</Heading>
                    <Paragraph size={400} color="muted">
                        {format(new Date(event.datetime), 'dd.MM.yyyy HH:mm')}
                    </Paragraph>
                </Pane>
                <Pane display="flex" padding={8}>
                    <Tablist>
                    {['Информация', 'Документы'].map((tab, index) => (
                        <Tab
                            key={tab}
                            isSelected={selectedIndex === index}
                            onSelect={() => setSelectedIndex(index)}
                        >
                            {tab}
                        </Tab>
                    ))}
                    </Tablist>
                </Pane>
                </Pane>
                <Pane flex="1" background="tint1" padding={16}>
                    {[
                        <Card
                            backgroundColor="white"
                            elevation={0}
                            padding={10}
                        >
                            <PhotoProvider>
                                <PhotoView src={`${config.hostname}${event.image_path}`}>
                                    <Image
                                        width={150}
                                        height={150}
                                        src={`${config.hostname}${event.image_path}`}
                                    />
                                </PhotoView>
                            </PhotoProvider>
                            <Paragraph>Тип события: {EVENTS_MAP[event.type].label}</Paragraph>
                            <Paragraph>Дата: {format(new Date(event.datetime), 'dd.MM.yyyy HH:mm')}</Paragraph>
                            <Paragraph>Место: {event.place.name} </Paragraph>
                            <Paragraph>Сотрудник: {`${event.actor.first_name} ${event.actor.middle_name} ${event.actor.last_name}`} </Paragraph>
                            <Paragraph>Телефон сотрудника: {event.actor.phone} </Paragraph>
                        </Card>,
                        <Card
                            backgroundColor="white"
                            elevation={0}
                            padding={10}
                            display="grid"
                            gap={10}
                        >
                            {
                                event.documents.length ? event.documents.map((doc) => (
                                    <Link 
                                        key={doc.id}
                                        href={`${config.hostname}${doc.filepath}`}
                                        target="_blank"
                                    >
                                        {doc.name} {' '}
                                        <Badge color="neutral" marginRight={8}>
                                            {DOCUMENT_TYPES.find((el) => el.value === doc.type)?.label}
                                        </Badge>
                                    </Link>
                                )) : <Paragraph>Не загружено ни 1 документа</Paragraph>
                            }
                        </Card>
                    ][selectedIndex]}
                </Pane>
            </SideSheet>
        </Card>
    );
};

const EVENTS_ICONS: {[K in IEventType]: ReactElement} = {
    inspection: <UpdatedIcon />,
    descent: <ArrowDownIcon />,
    lifting: <ArrowUpIcon />,
    production: <CogIcon />,
    acceptance: <RouteIcon />,
    coating: <NewLayersIcon />,
    coating_acceptance: <LayersIcon />,
    repair: <WrenchIcon />,
    write_off: <DisableIcon />
}


export default EventCard;
