import { Button, GeolocationIcon, HomeIcon, IconButton, Pane } from "evergreen-ui";
import { Link } from "react-router-dom";

import useAuthContext from "../AuthProvider/AuthContext.hook";
import useGeoContext from "../GeoProvider/GeoContext.hook";

import styles from './Header.module.scss';

const Header = () => {
    const { accessToken, setAccessToken } = useAuthContext();
    const { geoPopupHandlers } = useGeoContext()
    const exitHandler = () => setAccessToken(null);

    if (!accessToken) {
        return <div />
    }

    return (
        <header className={styles.header}>
            <Pane className={styles.header__links}>
                <Link to={'/'} className={styles.header__link}>
                    <HomeIcon /> Главная
                </Link>
            </Pane>
            <div>
                <IconButton 
                    appearance="minimal"
                    icon={GeolocationIcon}
                    onClick={geoPopupHandlers.setTrue}
                />
                <Button appearance="minimal" onClick={exitHandler} className={styles.header__exit}>
                    Выйти
                </Button>
            </div>
        </header>
    );
};

export default Header;
