import { useGeolocated } from "react-geolocated";
import { useBoolean, useLocalStorage } from "usehooks-ts";

import type { IPlace } from "../../../api/Types";
import type { ICoords } from "../../../models/ICoords";
import GeoContext from "./GeoContext";
import GeoPopup from "./GeoPopup/GeoPopup";
import useAuthContext from "../AuthProvider/AuthContext.hook";
import GeoEffects from "./GeoEffects";

export interface GeoProviderProps extends React.PropsWithChildren {};

const GeoProvider = ({ children }: GeoProviderProps) => {
    const authCtx = useAuthContext();
    const [confirmedPlace, setConfirmedPlace] = useLocalStorage<IPlace | null>('confirmedPlace', null);
    const [confirmedCoords, setConfirmedCoords] = useLocalStorage<ICoords | null>('confirmedCoords', null);

    const geoPopupHandlers = useBoolean(false);

    const geolocated = useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        userDecisionTimeout: 15_000,
    });

    return ( 
        <GeoContext.Provider 
            value={{
                geolocated,
                confirmedPlace, 
                setConfirmedPlace,
                confirmedCoords, 
                setConfirmedCoords,
                geoPopupHandlers
            }}
        >
            {children}
            {
                authCtx.accessToken
                    ? (
                        <>
                            <GeoPopup />
                            <GeoEffects/>
                        </>
                    ) : null
            }
        </GeoContext.Provider>
    )
};

export default GeoProvider;
