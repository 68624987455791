import { useRef, useState } from "react";
import { Button, Card, CrossIcon, DocumentIcon, Heading, Pane, Select, SideSheet, Text, TextInput, TrashIcon } from "evergreen-ui";
import { useBoolean } from "usehooks-ts";
import { uniqBy } from "lodash";

import type { IDocumentType, IUploadPipeEventDocumentData } from "../../../../../api/Types";

import { useScanResultContext } from "../../context/ScanResult.context";
import { DOCUMENT_TYPES } from "../../../../../api/references";
import NewDocCard from "./NewDocCard/NewDocCard";

import styles from './EventAttachments.module.scss';

const EventAttachments = () => {
    const { attachments, setAttachments } = useScanResultContext();

    const [innerAttachments, setInnerAttachments] = useState<Partial<IUploadPipeEventDocumentData>[]>([...attachments]);

    const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = ev.target;

        if (files) {
            setInnerAttachments((prevFiles) => uniqBy([
                ...prevFiles,
                ...[...files].map((file) => (
                    {
                        document: file,
                        document_name: file.name.replace(/\.[^/.]+$/, ""),
                        document_type: undefined
                    } satisfies Partial<IUploadPipeEventDocumentData>
                ))
            ], 'document.name')
            );
        }
    }

    const addDocument = (file: IUploadPipeEventDocumentData) => {
        setInnerAttachments((prevFiles) => [
            ...prevFiles,
            file
        ])
    }

    const onCloseComplete = () => {
        setInnerAttachments([...attachments])
        setIsOpenFalse();
    }

    const onOk = () => {
        setAttachments(innerAttachments as IUploadPipeEventDocumentData[]);
        setIsOpenFalse();
    }

    const checkDocuments = innerAttachments.reduce((acc, curr) => !!(acc && curr.document_name && curr.document_type), true );

    const inputFileRef = useRef<HTMLInputElement>(null);

    const { value: isOpen, setTrue: setIsOpenTrue, setFalse: setIsOpenFalse } = useBoolean();

    return (
        <>
            <Card>
                <Text>{`Добавлено файлов: ${attachments.length}`}</Text>
                <Button 
                    appearance="secondary"
                    onClick={setIsOpenTrue}
                >
                    Добавить документы
                </Button>
            </Card>
            <SideSheet 
                width="min(var(--max-app-with), 100vw)"
                isShown={isOpen}
                onCloseComplete={onCloseComplete}
                containerProps={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'column'
                }}
            >
                <Button
                    appearance="secondary"
                    borderRadius={100}
                    padding={5}
                    onClick={onCloseComplete}
                    className={styles.closeButton}
                    position="fixed"
                    zIndex={10}
                >
                    <CrossIcon />
                </Button>
                <Pane zIndex={1} flexShrink={0} elevation={0} backgroundColor="white">
                    <Pane 
                        padding={16} 
                        borderBottom="muted"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Heading size={600}>Добавить документы</Heading>
                    </Pane>
                </Pane>
                <Pane className={styles.attachmentsBody}>
                    <Card
                        display="grid"
                        gap={10}
                    >
                        <Button
                            appearance="primary"
                            onClick={onOk}
                            disabled={!checkDocuments}
                        >
                            Подтвердить
                        </Button>
                        <Button
                            appearance="secondary"
                            onClick={() => inputFileRef.current?.click()}
                        >
                            Загрузить файлы
                        </Button>
                        <input
                            type="file"
                            ref={inputFileRef}
                            value='' 
                            onChange={onChange}
                            multiple
                            hidden
                            accept=".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                        />
                    </Card>
                    <NewDocCard addDocument={addDocument} />
                    <Pane className={styles.attachmentsList}>
                        {
                            innerAttachments.map((el, i) => (
                                <Card 
                                    key={el.document?.name}
                                    className={styles.attachmentCard}
                                >
                                    <Pane
                                        background="white"
                                        borderRadius={4}
                                        padding={10}
                                    > 
                                        <DocumentIcon />
                                    </Pane>
                                    <Pane
                                        display="grid"
                                        gap={10}
                                    >
                                        <TextInput
                                            placeholder="Название документа"
                                            width="auto"
                                            marginBottom={0}
                                            value={el.document_name}
                                            isInvalid={!el.document_name}
                                            onChange={(event: { target: { value: string; }; }) => {
                                                setInnerAttachments((prevValue) => {
                                                    const newValue = [...prevValue];
                                                    newValue[i].document_name = event.target.value
                                                    return newValue;
                                                })
                                            }}
                                        >

                                        </TextInput>
                                        <Select
                                            value={el.document_type || ''}
                                            isInvalid={!el.document_type}
                                            onChange={(event) => {
                                                setInnerAttachments((prevValue) => {
                                                    const newValue = [...prevValue];
                                                    newValue[i].document_type = event.target.value as IDocumentType
                                                    return newValue;
                                                })
                                            }}
                                        >
                                            <option value="" hidden disabled>
                                                Выберите тип документа
                                            </option>
                                            {
                                                DOCUMENT_TYPES.map(({ label, value }) => (
                                                    <option 
                                                        key={value}
                                                        value={value}
                                                    >
                                                        {label}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                    </Pane>
                                    <Button
                                        padding={0}
                                        intent="danger"
                                        appearance="minimal"
                                        onClick={() => {
                                            setInnerAttachments((prevValue) => {
                                                const newValue = [...prevValue];
                                                return newValue.filter((el, k) => i !== k);
                                            })
                                        }}
                                    >
                                        <TrashIcon /> 
                                    </Button>
                                </Card>
                            ))
                        }
                    </Pane>
                </Pane>
            </SideSheet>
        </>
    );
};

export default EventAttachments;
