import { SetStateAction, createContext, useContext } from 'react';
import type QrScanner from 'qr-scanner';
import type { IUploadPipeEventDocumentData } from '../../../../api/Types';

export interface IScanResultContext {
    file: File | null;
    result: QrScanner.ScanResult['data'] | null;
    clearResults: () => void;
    isFromPhoto?: boolean;
    attachments: IUploadPipeEventDocumentData[];
    setAttachments: React.Dispatch<React.SetStateAction<IUploadPipeEventDocumentData[]>>;
}

export const DEFAULT: IScanResultContext = {
    file: null,
    result: null,
    clearResults: function (): void {
        throw new Error('Function not implemented.');
    },
    attachments: [],
    setAttachments: function (_value: SetStateAction<IUploadPipeEventDocumentData[]>): void {
        throw new Error('Function not implemented.');
    }
};

const ScanResultContext = createContext<IScanResultContext>(DEFAULT);

export const useScanResultContext = () => {
    const ctx = useContext(ScanResultContext);
    return ctx
}

export default ScanResultContext;

