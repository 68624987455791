import { useEffect, useRef, useState } from "react";
import { useMatch } from "react-router";
import axios from "axios";
import { Button, Pagination, Pane, Spinner} from "evergreen-ui";
import { format } from "date-fns";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { FeatureGroup, MapContainer, Marker, Polyline, Popup, TileLayer } from "react-leaflet";
import { Icon, Map } from "leaflet";
import { ceil, first, sortBy } from "lodash";
import classNames from "classnames";
import { useEventListener } from "usehooks-ts";

import useAuthContext from "../../app/components/AuthProvider/AuthContext.hook";
import { IGetPipeResponse } from "../../api/Types";
import markerIconSrc from '../../media/icons/marker-icon-2x.png';

import pointCoordsToArray from "../../app/helpers/pointCoordsToArray.helper";
import EventCard from "./components/EventCard/EventCard";
import config from "../../app/config";
import { EVENTS_MAP } from "../../app/helpers/events.helper";

import styles from './Pipe.module.scss';

const Pipe = () => {
    const match = useMatch('/pipes/:id');
    const { api } = useAuthContext();

    const [loadingPipeData, setLoadingPipeData] = useState<boolean>(true);
    const [pipe, setPipe] = useState<IGetPipeResponse | null>(null);
    const [error, setError] = useState<number | null>(null);

    const count = pipe?.events.length || 0;
    const [page, setPage] = useState<number>(1);
    const totalPages = ceil(count / PAGE_SIZE);

    const loadPipeData = async (id: number) => {
        setLoadingPipeData(true);
        try {
            const response = await api.getPipe(id);
            
            if (response.data) {
                setPipe(response.data);
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                setError(error.response?.status || null)
            }
        } finally {
            setLoadingPipeData(false);
        }
    }

    useEffect(() => {
        if (match?.params.id) {
            loadPipeData(+match.params.id);
        } else {
            setLoadingPipeData(false);
            setError(404);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match])

    const sortedEvents = pipe ? sortBy(pipe.events, (el) => -1 * new Date(el.datetime).getTime()) : [];

    const lastEvent = first(sortedEvents);

    // const expanded = useBoolean(false);
    const mapRef = useRef<Map>(null);

    // useEffect(() => {
    //     const mapContainer = mapRef.current?.getContainer();
    //     if (mapContainer && lastEvent) {
    //         mapContainer.classList[expanded.value ? 'add' : 'remove'](styles.map_expanded);
    //         mapRef.current?.invalidateSize();
    //         mapRef.current?.flyTo(pointCoordsToArray(lastEvent.place.point), expanded.value ? 14 : 10);
    //     }
    // }, [expanded]);
    
    useEventListener('resize', () => mapRef.current?.invalidateSize())

    if (loadingPipeData) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center">
                <Spinner />
            </Pane>
        )
    }

    if (error) {
        return (
            <Pane display="flex" alignItems="center" justifyContent="center">
                <h1>{error}</h1>
            </Pane>
        )
    }
    if (pipe && lastEvent) {
        return (
            <Pane>
                <h1>
                    Серийный номер: {pipe.serial_number}
                </h1>
                {/* <Button appearance="secondary" onClick={expanded.toggle}>toggle</Button> */}
                <MapContainer 
                    center={pointCoordsToArray(lastEvent.place.point)} 
                    // zoom={10}
                    // zoomControl={false}
                    // className={styles.map}
                    zoom={14}
                    className={classNames(styles.map, styles.map_expanded)}
                    ref={mapRef}
                >
                    {/* {
                        expanded.value ? (
                            <ZoomControl />
                        ) : null
                    } */}
                    <TileLayer
                        attribution='&copy; <a href="https://carto.com/">carto.com</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
                    />
                    <FeatureGroup>
                        {
                            sortedEvents.map((event) => (
                                <Marker 
                                    key={event.id}
                                    position={pointCoordsToArray(event.place.point)}
                                    // icon={divIcon({
                                    //     iconUrl: `${config.hostname}/${event.image_path}`,
                                    //     iconSize: [34, 34],  
                                    //     iconAnchor: [17, 17],
                                    //     className: styles.mapIconMarker,
                                    //     html: `<img src="${config.hostname}/${event.image_path}" class="${styles.mapIcon}"/>`
                                    // })}
                                    icon={new Icon({
                                        iconUrl: markerIconSrc,
                                        iconSize: [25, 41],  
                                        iconAnchor: [13, 41], 
                                    })}
                                >
                                    <Popup>
                                        <div className={classNames(styles.event, styles.event_white, styles.event_popup)}>
                                            <p className={styles.event__date}>Дата: {format(new Date(event.datetime), 'dd.MM.yyyy HH:mm')}</p>
                                            <p className={styles.event__placeName}>Место: {event.place.name} </p>
                                            <p className={styles.event__type}>Событие: {EVENTS_MAP[event.type]?.label} </p>
                                            <PhotoProvider>
                                                <PhotoView src={`${config.hostname}${event.image_path}`}>
                                                    <Button appearance="secondary" className={styles.event__image}>
                                                        Открыть фото
                                                    </Button>
                                                </PhotoView>
                                            </PhotoProvider>
                                            <div className={styles.event__actor}>
                                                <p>{event.actor.first_name} {event.actor.middle_name} {event.actor.last_name}</p>
                                                <p>{event.actor.phone}</p>
                                            </div>
                                        </div>
                                    </Popup>
                                </Marker>
                            ))
                        }
                        <Polyline 
                            positions={sortedEvents.map((event) => pointCoordsToArray(event.place.point))} 
                            color="red" 
                        />
                    </FeatureGroup>
                </MapContainer>
                <h2>
                    Все события:
                </h2>
                <div className={styles.eventList}>
                    {
                        sortedEvents
                            .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
                            .map((event) => (<EventCard event={event} key={event.id} />)
                        )
                    }
                    {
                        totalPages > 1 ? (
                            <Pagination 
                                className={styles.pagination}
                                page={page} 
                                totalPages={totalPages}
                                onPageChange={setPage}
                                onNextPage={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages))}
                                onPreviousPage={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                            />
                        ) : null
                    }
                </div>
            </Pane>
        );
    }

    return null;
};

const PAGE_SIZE = 5;

export default Pipe;
