import { useRef, useState } from "react";
import { Button, Card, EyeOpenIcon, Image, Pane, Pill, Select, TextInput, TrashIcon } from "evergreen-ui";
import { useBoolean } from "usehooks-ts";
import type { jsPDFOptions } from "jspdf";
import { jsPDF } from "jspdf";
import { uniqBy } from "lodash";

import { IDocumentType, IUploadPipeEventDocumentData } from "../../../../../../api/Types";
import { DOCUMENT_TYPES } from "../../../../../../api/references";

import styles from './NewDocCard.module.scss';
import { format } from "date-fns";
import { PhotoProvider, PhotoView } from "react-photo-view";

export interface NewDocCardProps {
    addDocument: (file: IUploadPipeEventDocumentData) => void
};

const NewDocCard = ({ addDocument }: NewDocCardProps) => {
    const edditing = useBoolean();
    const loading = useBoolean();

    const [doc, setDoc] = useState<Partial<IUploadPipeEventDocumentData>>({
        document: undefined,
        document_name: '',
        document_type: undefined
    });

    const [photos, setPhotos] = useState<File[]>([]);

    const clear = () => {
        setPhotos([]);
        setDoc({
            document: undefined,
            document_name: '',
            document_type: undefined
        })
        edditing.setFalse();
    }

    const inputFileRef = useRef<HTMLInputElement>(null);

    const onChangeFileInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = ev.target;
        if (files) {
            setPhotos((prevState) => uniqBy([
                ...prevState,
                ...[...files].map((file) => 
                    new File([file], `${file.lastModified}-${file.name}`, { type: file.type })
                )
            ], 'name'))
        }
    }

    const canSave = photos.length && doc.document_name && doc.document_type;

    const saveFile = async () => {
        loading.setTrue();
        const pdf = await getPdf();
        
        if (pdf) {
            addDocument({...doc, document: pdf} as IUploadPipeEventDocumentData);
        }
        loading.setFalse();
        clear();
    }

    const getPdf = async () => {
        if (doc.document_name && doc.document_type) {
            const pdf = new jsPDF(options);
            
            const a = photos.map(async (photo, i) => {
                if (i > 0) {
                    pdf.addPage("l");
                }
                
                const imageData = URL.createObjectURL(photo);
    
                const img = document.createElement("img");
                img.src = imageData;
                await img.decode()
                const { naturalWidth: width, naturalHeight: height} = img;
    
                pdf.setPage(i + 1)
                pdf.addImage({
                    imageData,
                    x: 0,
                    y: 0,
                    width: 431.25,
                    height: 431.25 * height / width,
                })
            })

            await Promise.all(a);
            return new File([pdf.output('blob')], `${doc.document_name}-${getDocName()}`);
        }
    }

    return (
        <Card className={styles.newDocCard}>
            {
                !edditing.value ? (
                    <Button 
                        size="large"
                        appearance="minimal"
                        onClick={edditing.setTrue}
                    >
                        Создать документ из фото
                    </Button>
                ) : (
                    <Card
                        display="grid"
                        gap={10}
                    >
                        <Pane
                            display="grid"
                            gridAutoFlow="column"
                            gap={10}
                        >
                            <Button
                                appearance="primary"
                                intent="danger"
                                onClick={clear}
                            >
                                Удалить
                            </Button>
                            <Button
                                appearance="primary"
                                disabled={!canSave}
                                onClick={saveFile}
                                isLoading={loading.value}
                            >
                                Сохранить
                            </Button>
                        </Pane>
                        <Pane
                            display="grid"
                            gap={10}
                        >
                            <TextInput
                                placeholder="Название документа"
                                width="auto"
                                marginBottom={0}
                                value={doc.document_name}
                                isInvalid={!doc.document_name}
                                onChange={(event: { target: { value: string; }; }) => {
                                    setDoc((prevValue) => ({
                                        ...prevValue,
                                        document_name: event.target.value
                                    }))
                                }}
                            >

                            </TextInput>
                            <Select
                                value={doc.document_type || ''}
                                isInvalid={!doc.document_type}
                                onChange={(event) => {
                                    setDoc((prevValue) => ({
                                        ...prevValue,
                                        document_type: event.target.value as IDocumentType
                                    }))
                                }}
                            >
                                <option value="" hidden disabled>
                                    Выберите тип документа
                                </option>
                                {
                                    DOCUMENT_TYPES.map(({ label, value }) => (
                                        <option 
                                            key={value}
                                            value={value}
                                        >
                                            {label}
                                        </option>
                                    ))
                                }
                            </Select>
                        </Pane>
                        <Pane
                            display="grid"
                            gridTemplateColumns="1fr 1fr 1fr"
                            gap={10}
                        >
                            <PhotoProvider>
                                {photos.map((photo, i) => (
                                    <Card 
                                        key={photo.name}
                                        className={styles.photoCard}
                                        position="relative"
                                    >
                                        <Pill
                                            position="absolute"
                                            left={0}
                                            top={0}
                                            zIndex={5}
                                            color="blue"
                                        >
                                            {i + 1}
                                        </Pill>
                                        <Pane
                                            position="absolute"
                                            right={0}
                                            top={0}
                                            zIndex={5}
                                            display="grid"
                                            gridAutoFlow="column"
                                            gap={5}
                                        >
                                            <PhotoView src={URL.createObjectURL(photo)}>
                                                <Button
                                                    appearance="secondary"
                                                    size="small"
                                                    padding={0}
                                                >
                                                    <EyeOpenIcon />
                                                </Button>
                                            </PhotoView>
                                            <Button 
                                                padding={0}
                                                appearance="primary"
                                                intent="danger"
                                                size="small"
                                                onClick={() => setPhotos((prevValue) => [...prevValue].filter((el, k) => i !== k))}
                                            >
                                                <TrashIcon /> 
                                            </Button>
                                        </Pane>
                                        <Image
                                            width="100%"
                                            height="auto"
                                            src={URL.createObjectURL(photo)} 
                                        />
                                    </Card>
                                ))}
                            </PhotoProvider>
                            <Button
                                className={styles.photoCard}
                                onClick={() => inputFileRef.current?.click()}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: 0,

                                    height: '100%',
                                    width: '100%',
                                    aspectRatio: 1,

                                    borderRadius: '4px',
                                    overflow: 'hidden',
                                    backgroundColor: '#b7b7b7',
                                    whiteSpace: 'normal',
                                    lineHeight: 1
                                }}
                            >
                                Загрузить 
                                <br />
                                фотографии
                            </Button>
                        </Pane>
                        <input 
                            type="file" 
                            onChange={onChangeFileInput}
                            ref={inputFileRef} 
                            value=''
                            hidden
                            multiple
                            accept="image/png, image/jpeg" 
                        />
                    </Card>
                )
            }
        </Card>
    );
};

export const options: jsPDFOptions = {
    orientation: 'p',
    unit: 'px',
    format: 'a4',
    putOnlyUsedFonts: true
};

export const getDocName = () => `scan-${format(new Date(), 'dd.MM.yyyy HH:mm')}.pdf`;

export default NewDocCard;
