import jwtDecode from "jwt-decode";
import { isString } from "lodash";

import { IPipeToken } from "../../api/Types";
import isPipeIdWithHash from "./isPipeIdWithHash.helper";

const decodePipeId = (token?: string | IPipeToken | null) => {
    if (!isString(token)) {
        return false
    }
    
    const match = token.match(/(\d+);(.*)/);
    if (match && isPipeIdWithHash(token)) {
        return {
            id: +match[1],
            token: match[2]
        };
    }

    try {
        return jwtDecode<{ id: number; }>(token);
    } catch (error) {
        return false
    }
};

export default decodePipeId;
