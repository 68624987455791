import { Card, Paragraph, SelectField } from "evergreen-ui";

import ImageFromFile from "../../../ImageFromFile/ImageFromFile";
import useGeoContext from "../../../GeoProvider/GeoContext.hook";
import { IEventType } from "../../../../../api/Types";
import { EVENTS } from "../../../../helpers/events.helper";

import styles from './EventCard.module.scss';
import EventAttachments from "../EventAttachments/EventAttachments";

export interface EventCardProps {
    file?: File | null;
    setEventType: React.Dispatch<IEventType>;
    eventType: IEventType | null;
    availableEvents: IEventType[];
};

const EventCard = ({ setEventType, eventType, file, availableEvents }: EventCardProps) => {
    const geoCtx = useGeoContext();

    const availableEventsMap = EVENTS.filter((el) => availableEvents.includes(el.value)); 

    return (
        <Card className={styles.resultCard}>
            {file ? (
                <ImageFromFile file={file} className={styles.resultCard__image} />
            ) : null}
            <div className={styles.resultCard__coords}>
                Место: {geoCtx.confirmedPlace?.name}
            </div>
            <div className={styles.resultCard__event}>
                {
                    availableEventsMap.length ? (
                        <SelectField
                            value={eventType || ''}
                            label="Событие:"
                            onChange={(event) => setEventType(event.target.value as IEventType)}
                            marginBottom={10}
                        >
                            <option
                                value=''
                                disabled
                                hidden
                            >
                                Тип события
                            </option>
                            {
                                EVENTS.map((event) => (
                                    <option 
                                        key={event.value}
                                        value={event.value}
                                        disabled={!availableEvents.includes(event.value)}
                                    >
                                        {event.label}
                                    </option>
                                ))
                            }
                        </SelectField>
                    ) : <Paragraph>Нет доступных событий</Paragraph>
                }
            </div>
            <div className={styles.resultCard__attachments}>
                <EventAttachments />
            </div>
        </Card>
    );
};

export default EventCard;
