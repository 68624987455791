import { useEffect, useState } from "react";
// import QrScanner from "qr-scanner";
import { Button, Card, Heading } from 'evergreen-ui';
import { BottomSheet } from "react-spring-bottom-sheet";
import { useNavigate } from "react-router";

import type { IEventType, IPipeIdWithHash, IPipeToken, IUploadPipeEventDocumentData } from "../../../api/Types";

import EventCard from "./components/EventCard/EventCard";
import decodePipeId from "../../helpers/decodePipeId";
import { useIsAddingState, useSendData, useAvailableEvents } from "./hooks";

import styles from './ScanResult.module.scss';
import ScanResultContext from "./context/ScanResult.context";

export interface ScanResultProps {
    result: IPipeToken | IPipeIdWithHash | null,
    cancel: () => void;
    file: File | null;
    getImage: () => void;
    isFromPhoto?: boolean;
    isPhotoRetake?: boolean;
};

const ScanResult = ({ result, cancel: clearResults, isFromPhoto, file, getImage, isPhotoRetake }: ScanResultProps) => {
    const navigate = useNavigate();

    const decoded = decodePipeId(result);

    const [eventType, setEventType] = useState<IEventType | null>(null);
    const [attachments, setAttachments] = useState<IUploadPipeEventDocumentData[]>([]);

    const { availableEvents } = useAvailableEvents({ decoded, result });
    const { sendDataHandler, loading, dataChecked } = useSendData({ file, attachments, result, eventType, decoded, clearResults });
    const { isAddingState, setTrueIsAddingState } = useIsAddingState({ result });
    
    useEffect(() => {
        if (!isFromPhoto && result) {
            getImage();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFromPhoto, result])

    return (
        <ScanResultContext.Provider
            value={{
                file,
                result,
                clearResults,
                isFromPhoto,
                attachments, 
                setAttachments
            }}
        >
            <BottomSheet 
                open={!!result}
                onDismiss={clearResults}
                blocking={false}
                className={styles.resultSheet}
                header={isAddingState ? ( 
                    <div className={styles.resultActions}>
                        <Button
                            onClick={decoded ? () => navigate(`/pipes/${decoded.id}`) : undefined}
                            appearance="secondary"
                        >
                            Смотреть информацию о трубе
                        </Button>
                    </div>
                    ) : null
                }
                footer={isAddingState ? (
                    <div className={styles.resultActions}>
                        {
                            isFromPhoto ? null : (
                                <Button
                                    appearance="primary"
                                    onClick={getImage}
                                    isLoading={isPhotoRetake}
                                >
                                    Переснять фото
                                </Button>
                            )
                        }
                        <Button 
                            isLoading={loading}
                            disabled={!dataChecked}
                            appearance="primary"
                            onClick={sendDataHandler}
                        >
                            {dataChecked ? 'Подтвердить' : 'Выберите тип события'}
                        </Button>
                    </div>
                    ) : null
                }
            >
                {isAddingState ? (
                    <EventCard
                        file={file}
                        eventType={eventType}
                        setEventType={setEventType} 
                        availableEvents={availableEvents}
                    /> 
                ) : (
                    <Card className={styles.resultActionsCard}>
                        <Heading marginBottom={20}>
                            Выберите действие
                        </Heading>
                        <Button
                            appearance="secondary"
                            onClick={decoded ? () => navigate(`/pipes/${decoded.id}`) : undefined}
                        >
                            Смотреть информацию о трубе
                        </Button>
                        <Button
                            appearance="primary"
                            onClick={setTrueIsAddingState}
                        >
                            Добавить новое событие
                        </Button>
                    </Card>
                )} 
            </BottomSheet>
        </ScanResultContext.Provider>
    );
};

ScanResult.defaultProps = {
    isFromPhoto: false,
    isPhotoRetake: false
}

export default ScanResult;
