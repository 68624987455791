import isPipeToken from "../isPipeToken.helper";

import jsonMap from './map.json';

const map = new Map(Object.entries(jsonMap));

const QRAdapter = (data: any) => {
    const mappedToken = map.get(data);
    
    if (mappedToken) {
        console.warn('got mapped token');
        return mappedToken;
    }
    try {
        const url = new URL(data);
        const pipeToken = url.searchParams.get('pt')
        if (isPipeToken(pipeToken)) {
            return pipeToken;
        }
    } catch (error) {}
    return data
}

export default QRAdapter;
