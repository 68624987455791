import { useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import { toaster } from "evergreen-ui";

import Api from "../../../api/api";
import config from "../../config";
import AuthContext from "./AuthContext";

export interface AuthProviderProps extends React.PropsWithChildren {};

// TODO: refresh

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [accessToken, setAccessToken] = useLocalStorage<string | null>('accessToken', null);
    const api = useMemo(() => 
        new Api({
            axiosParams: {
                baseURL: `${config.hostname}/api`,
                headers: accessToken ? { Authorization: `Bearer ${accessToken}` } : {}
            },
            onUnauthorized: () => {
                toaster.warning('Сессия истекла, повторите вход');
                setAccessToken(null);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [accessToken]
    );

    return (
        <AuthContext.Provider value={{ accessToken, setAccessToken, api}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
