import { Button, Pane, TextInputField } from "evergreen-ui";
import { Form, Formik } from "formik";
import { ICoords } from "../../../../../models/ICoords";
import useAuthContext from "../../../AuthProvider/AuthContext.hook";
import { IAddPlaceData, IPlace } from "../../../../../api/Types";

import styles from '../GeoPopup.module.scss';

export interface NewPlaceDialogProps {
    coords: ICoords;
    callback: (value: IPlace) => void;
    place: IPlace | null
};

const NewPlaceDialog = ({ coords, callback, place }: NewPlaceDialogProps) => {
    const { api } = useAuthContext();

    const onSubmit = async (values: IAddPlaceData) => {
        try {
            const response = await api.addPlace(values);
            if (response.data) {
                callback({
                    id: response.data.id,
                    name: values.name,
                    point: {
                        lat: values.lat,
                        long: values.long
                    },
                    m_distance: 0
                });
            }
        } catch (error) {
            //
        }
    }

    if (place) {
        return null
    }

    return (
        <Pane>
            <Formik
                initialValues={{
                    name: '',
                    lat: coords[0],
                    long: coords[1]
                }}

                onSubmit={onSubmit}
            >
                {({ handleChange, values }) => (
                    <Form>
                        <Pane className={styles.addNewPlace}>
                            <TextInputField
                                name="name" 
                                margin={0}
                                label="Введите название нового места"
                                placeholder="Название"
                                value={values.name} 
                                onChange={handleChange}
                                disabled={!!place}
                            />
                            <Button
                                appearance={place ? 'default' : 'primary'}
                                type="submit"
                                disabled={!!place || values.name.length < 1}
                            >
                                Добавить новое место
                            </Button>
                        </Pane>
                    </Form>
                )}
            </Formik>
        </Pane>
    );
};

export default NewPlaceDialog;
