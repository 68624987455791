import { useMap, useMapEvent } from "react-leaflet"

const ClosePopup = () => {
    const map = useMap();
    
    useMapEvent('dragstart', (e) => {
        map.closePopup()
    })
    
    return null;
};

export default ClosePopup;
