import { useEffect } from "react";
import { useBoolean } from "usehooks-ts";
import type QrScanner from "qr-scanner";

export interface IIsAddingState {
    result: QrScanner.ScanResult['data'] | null;
}

const useIsAddingState = ({ result }: IIsAddingState) => {
    const { value: isAddingState, setTrue: setTrueIsAddingState, setFalse: setFalseIsAddingState } = useBoolean();

    useEffect(() => {
        if (!result) setFalseIsAddingState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result])
    
    return { isAddingState, setTrueIsAddingState, setFalseIsAddingState };
};

export default useIsAddingState;
