import { useCallback, useEffect } from 'react';
import { useWindowSize } from 'usehooks-ts';

const AppHeight = () => {
    const { height } = useWindowSize();

    const setAppHeight = useCallback(() => {
        document.body.setAttribute('style', `--app-height: ${height}px`);
    }, [height]);

    useEffect(setAppHeight, [setAppHeight]);

    return null;
};

export default AppHeight;
