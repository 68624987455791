import { BrowserRouter, Route } from 'react-router-dom';
import { Routes } from 'react-router';

import AppHeight from './components/AppHeight/AppHeight';
import QRPage from '../pages/QRPage/QRPage';
import Login from '../pages/Login/Login';
import AuthProvider from './components/AuthProvider/AuthProvider';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';
import Pipe from '../pages/Pipe/Pipe';
import Header from './components/Header/Header';
import GeoProvider from './components/GeoProvider/GeoProvider';

import styles from './App.module.scss';

const App = () => (
  <div className={styles.App}>
    <AppHeight />
    <AuthProvider>
      <BrowserRouter>
        <GeoProvider>
            <Header />
            <main className={styles.appMain}>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/pipes/*" element={<ProtectedRoute><Pipe /></ProtectedRoute>} />
                <Route path="/" element={<ProtectedRoute><QRPage /></ProtectedRoute>} />
              </Routes>
            </main>
        </GeoProvider>
      </BrowserRouter>
    </AuthProvider>
  </div>
)

export default App;
