import { useState, useLayoutEffect } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";

export interface ImageFromFileProps extends Omit<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, 'src'> {
    file: File | null;
};

const ImageFromFile = ({ file, ...imgProps }: ImageFromFileProps) => {
    const [imgSrc, setImgSrc] = useState<string>('');

    useLayoutEffect(() => {
        if (file) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''), {
                once: true
            });
            reader.readAsDataURL(file);
        }
    }, [file]);

    // return imgSrc ? <img src={imgSrc} alt={imgProps.alt} {...imgProps} /> : null;

    return imgSrc ? (
        <PhotoProvider>
            <PhotoView src={imgSrc}>
                <img src={imgSrc} alt={imgProps.alt} {...imgProps} />
            </PhotoView>
        </PhotoProvider>
    ) : null
};

export default ImageFromFile;
