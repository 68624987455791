import { createContext } from 'react';
import { GeolocatedResult } from 'react-geolocated';

import type { IPlace } from '../../../api/Types';
import type { UseBooleanOutput } from '../../../models/UseBooleanOutput';
import type { ICoords } from '../../../models/ICoords';

export interface IGeoContext {
    geolocated: GeolocatedResult;
    confirmedPlace: IPlace | null;
    setConfirmedPlace: React.Dispatch<IPlace | null>;
    confirmedCoords: ICoords | null; 
    setConfirmedCoords: React.Dispatch<ICoords | null>;
    geoPopupHandlers: UseBooleanOutput
}

export const DEFAULT: IGeoContext = {
    geolocated: {
        coords: undefined,
        timestamp: undefined,
        isGeolocationAvailable: false,
        isGeolocationEnabled: false,
        positionError: undefined,
        getPosition: function (): void {
            throw new Error('Function not implemented.');
        }
    },
    confirmedPlace: null,
    setConfirmedPlace: function (_value: IPlace | null): void {
        throw new Error('Function not implemented.');
    },
    confirmedCoords: null,
    setConfirmedCoords: function (_value: ICoords | null): void {
        throw new Error('Function not implemented.');
    },
    geoPopupHandlers: {
        value: false,
        setValue: undefined,
        setTrue: function (): void {
            throw new Error('Function not implemented.');
        },
        setFalse: function (): void {
            throw new Error('Function not implemented.');
        },
        toggle: function (): void {
            throw new Error('Function not implemented.');
        }
    }
};

export default createContext<IGeoContext>(DEFAULT);
