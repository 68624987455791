import { createContext } from 'react';
import Api from '../../../api/api';

export interface IAuthContext {
    accessToken: string | null;
    setAccessToken: React.Dispatch<React.SetStateAction<string | null>>;
    api: Api
}

export const DEFAULT: IAuthContext = {
    accessToken: null,
    setAccessToken: function (_value: React.SetStateAction<string | null>): void {
        throw new Error('Function not implemented.');
    },
    api: new Api()
};

export default createContext<IAuthContext>(DEFAULT);
