import { Pane } from 'evergreen-ui';
import { useEffect } from 'react';

import CameraQRScanner from "../../app/components/CameraQRScanner/CameraQRScanner";
import useGeoContext from '../../app/components/GeoProvider/GeoContext.hook';

const QRPage = () => {
    const { confirmedPlace, geoPopupHandlers } = useGeoContext()

    useEffect(() => {
        if (!confirmedPlace) {
            geoPopupHandlers.setTrue();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmedPlace]);

    return (
        <Pane maxWidth={390}>
            <CameraQRScanner />
        </Pane>
    );
};

export default QRPage;
