import { IEventType } from "../../api/Types";

type EventDetails = {
    label: string;
    value: IEventType;
};

export const EVENTS: EventDetails[] = [
    { label: 'Производство НКТ', value: 'production' },
    { label: 'Приемка НКТ', value: 'acceptance' },
    { label: 'Покрытие majorpack', value: 'coating' },
    { label: 'Приемка НКТ с покрытием', value: 'coating_acceptance' },
    { label: 'Спуск НКТ', value: 'descent' },
    { label: 'Подъем НКТ', value: 'lifting' },
    { label: 'Ремонт НКТ', value: 'repair' },
    { label: 'Списание НКТ', value: 'write_off' },
    { label: 'Инспекция НКТ', value: 'inspection' }
];

export const EVENTS_MAP = EVENTS.reduce((acc, curr) => ({ ...acc, [curr.value]: curr}), {}) as {[K in IEventType]: EventDetails}
